<template>
  <div class="container mt-5">
    <div class="row d-flex justify-content-center">
      <div class="col col-6">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            Invoices upload
          </div>
          <div class="card-body w-100 p-5 position-relative">
            <div class="upload-container">
              <h4>Upload invoice file here</h4>
              <form id="upload-invoice-form">
                <FileUploadInput
                  ref="file-upload-input"
                  description="Put your file here"
                  :acceptedExtensions="acceptedExtensions"
                  :errors="inputFileErrors"
                  @blur="validateInputFile"
                >
                  <template #label-html>
                    CSV (<span class="text-info">.csv</span>) file:
                  </template>
                </FileUploadInput>
              </form>
            </div>
          </div>
          <div
            v-if="uploadingInProgress"
            class="position-absolute d-flex align-items-center w-100 justify-content-center h-100 spinner-background"
          >
            <Spinner />
          </div>
          <div class="card-footer">
            <div class="float-end">
              <button
                class="btn btn-primary"
                :disabled="isUploadButtonDisabled"
                @click="uploadFile"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="serverValidationErrors.length > 0"
          class="card mt-5"
        >
          <div class="card-header d-flex justify-content-between bg-danger">
            File validation errors
          </div>
          <div class="card-body w-100 p-5 bg-opacity-10 bg-danger">
            <p
              v-for="(error, index) in serverValidationErrors"
              :key="index"
            >
              <span class="font-weight-bold">{{ index + 1 }}</span>.
              {{ error }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card mt-5 m-auto"
      style="width: 35em;"
    >
      <div class="card-header p-3 d-flex justify-content-start">
        Uploaded invoices
      </div>
      <div
        v-if="invoicesData.length"
        class="card-body p-0 d-flex justify-content-center"
        style="height: 32.5em;"
      >
        <ag-grid-vue
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :pagination="true"
          paginationPageSize="10"
          :rowData="getSortedInvoices()"
          class="ag-theme-alpine h-100 w-100"
        />
      </div>
      <Spinner v-else />
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import FileUploadInput from '@/components/common/FileUploadInput';
import { mapActions, mapGetters } from 'vuex';
import Spinner from '@/components/common/Spinner';
import { AgGridVue } from 'ag-grid-vue3';

const MAX_FILE_SIZE = 120 * 1024 * 1024; // 120 Megabytes
export default {
  components: {
    FileUploadInput,
    Spinner,
    AgGridVue,
  },
  data() {
    return {
      uploadingInProgress: false,
      inputFileErrors: [],
      serverValidationErrors: []
    };
  },
  computed: {
    ...mapGetters({
      invoicesData: 'invoicesUpload/invoices'
    }),
    acceptedExtensions() {
      return '.csv';
    },
    isUploadButtonDisabled() {
      return this.inputFileErrors.length || !this?.$refs['file-upload-input']?.getFile();
    },
    defaultColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    columnDefs() {
      return [
        {
          headerName: 'File name',
          field: 'filename',
          width: 340
        },
        {
          headerName: 'Upload date',
          field: 'updatedDate',
          width: 210,
          sortable: true,
          valueFormatter: ({ value }) => this.formatDate(value)
        }
      ];
    }
  },
  async mounted() {
    await this.fetchInvoices();
  },
  methods: {
    ...mapActions({
      uploadInvoiceFile: 'invoicesUpload/uploadInvoiceFile',
      fetchInvoices: 'invoicesUpload/fetchInvoices'
    }),
    validateInputFile() {
      this.inputFileErrors = [];
      const file = this.$refs['file-upload-input'].getFile();

      if (!file) {
        this.inputFileErrors.push({
          $uid: 'file-is-required-error',
          $message: 'The file is required'
        });
        return;
      }

      const fileExtension = _.last(file.name.split('.'));
      if (this.acceptedExtensions.indexOf(fileExtension) === -1) {
        this.inputFileErrors.push({
          $uid: 'file-extension-is-not-allowed-error',
          $message: 'The file extension is not allowed'
        });
      }

      const fileSize = file.size;
      if (fileSize > MAX_FILE_SIZE) {
        this.inputFileErrors.push({
          $uid: 'file-is-too-big',
          $message: 'The file is too big. Please contact administrator for further instructions.'
        });
      }
    },
    async uploadFile() {
      this.uploadingInProgress = true;
      this.serverValidationErrors = [];
      this.validateInputFile();
      if (this.inputFileErrors.length > 0) {
        this.$toast.error('The file must be valid');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.$refs['file-upload-input'].getFile());

      try {
        await this.uploadInvoiceFile(formData);
        this.$toast.success('The file uploaded successfully');
        await this.fetchInvoices();
        this.$refs['file-upload-input'].reset();
        this.inputFileErrors = [];
      } catch (error) {
        const { errorDetails } = error.cause;
        this.serverValidationErrors = errorDetails;
        this.$toast.error('Please fix the file');
      } finally {
        this.uploadingInProgress = false;
      }
    },
    formatDate(date) {
      if (date) {
        return new Date(date).toLocaleTimeString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        });
      }
    },
    getSortedInvoices(){
      return _.orderBy(this.invoicesData, ['updatedDate'], ['desc']);
    }
  }
};
</script>
<style scoped>
.font-weight-bold {
  font-weight: bold;
}
.spinner-background {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
